<template>
    <div>
        <template v-if="isLoading"><Spinner /></template>

        <div class="w-100-l w-90 center pv4-l pv3 mini-spacing">
            <div class="flex flex-wrap justify-end items-center center w-60">
                <div class="action-btns pv3 ph5-l">
                    <a href="#" @click.prevent="handleDownloadPDF(invoiceFormRef, invoice.number)" class="mr-2 ph4">
                        <img src="../../assets/images/direct-download.svg" alt="" /> Download
                    </a>
                    <!-- <a href="#" @click.prevent="printInvoice">
                        <img src="../../assets/images/printer.svg" alt=""/>
                    </a> -->
                </div>
            </div>
            <div class="flex flex-wrap justify-between w-90 center">
                <div id="invoiceContainer" ref="invoiceFormRef" class="w-60-l w-100 mb4 mb0-l center">
                    <div class="box-border pv4" style="background: #FBFCFE;">
                        <div class="w-90 center pa3-l pa2 box-border" style="background: white;">
                            <div class="flex flex-wrap justify-between pv3">
                                <div class="font-w1 pb3 pb0-l">Invoice No {{ invoice.number }}</div>
                                <div class="flex">
                                    <div>Date issued: {{ formatDate(invoice.dateIssued) }}</div>
                                    <div class="pl3">Date due: {{ formatDate(invoice.dateDue) }}</div>
                                </div>
                            </div>
                            <div class="pa3" style="background: #F7F9FD;">
                                <div class="flex items-center justify-between pb2">
                                    <div>
                                        <div class="f3 b pb2">
                                            Invoice
                                            <span
                                                v-if="invoice.status == 'draft'"
                                                class="ml3 tc ph3 pv1 invoice-status-badge alert-warning"
                                                >Draft</span
                                            >
                                            <span
                                                v-else-if="invoice.status?.includes('paid')"
                                                class="ml3 tc ph3 pv1 invoice-status-badge alert-success"
                                                >Paid</span
                                            >
                                            <span v-else class="ml3 tc ph3 pv1 invoice-status-badge alert-danger">Unpaid</span>
                                        </div>
                                        <div>{{ invoice.description }}</div>
                                    </div>
                                    <div class="db-l dn">
                                        <img src="imgs/BA-large.svg" alt="" />
                                    </div>
                                </div>
                                <div class="flex flex-wrap justify-between bill-section">
                                    <div class="pv3">
                                        <div class="b pb2" style="color: #132C8C;">Bill to</div>
                                        <div>
                                            <div class="pb2 b">{{ invoice.customer?.name }}</div>
                                            <div class="pb2">{{ invoice.customer?.address }}</div>
                                            <div class="pb2">{{ invoice.customer?.email }}</div>
                                            <div class="pb2">{{ invoice.customer?.phone }}</div>
                                        </div>
                                    </div>
                                    <div class="pv3 tr-lx tl">
                                        <div class="b pb2" style="color: #132C8C;">Bill from</div>
                                        <div class="flex justify-center items-center">
                                            <div class="org-img-placeholder">
                                                <div ref="companyLogoWrapper" v-if="invoice?.business?.orgImage">
                                                    <img
                                                        ref="companyLogo"
                                                        :src="invoice?.business?.orgImage"
                                                        alt=""
                                                        style="object-position: center; object-fit: cover; width: 60px; height: 60px;"
                                                        class="rounded-circle"
                                                    />
                                                </div>
                                                <span v-else>{{ orgInitials }}</span>
                                            </div>
                                            <div>
                                                <div class="pb2x b">{{ invoice.business?.name }}</div>
                                                <div class="pb2">{{ invoice.business?.address }}</div>
                                                <div class="pb2">{{ invoice.business?.email }}</div>
                                                <div class="pb2">{{ invoice.business?.phone }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="pa3-l pa2">
                                <div class="flex justify-between pv3 box-border-bottom font-w1">
                                    <div class="w-30">Item</div>
                                    <div class="w-60 flex justify-between">
                                        <div class="w-30 tl">Cost</div>
                                        <div class="w-30 tc">Qty/Unit</div>
                                        <div class="w-30 tr">Price</div>
                                    </div>
                                </div>
                            </div>
                            <div v-for="item of invoice.items" v-bind:key="item.id" class="pa3-l pa2">
                                <div class="flex justify-between pb3 box-border-bottom">
                                    <div class="w-30">
                                        <div class="b">{{ item.name }}</div>
                                        <div v-if="item.notes" class="text-muted" style="font-size:0.9em">{{ item.notes }}</div>
                                    </div>
                                    <div class="w-60 flex justify-between">
                                        <div class="w-30 tl">{{ item.price }}</div>
                                        <div class="w-30 tc">{{ item.quantity }} {{item.measurement}}</div>
                                        <div class="w-30 tr">
                                            <div v-if="item.discount > 0">
                                                <div class="text-muted" style="text-decoration:line-through;">
                                                    {{
                                                        formatAmount(
                                                            item.price * item.quantity,
                                                            invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                                <div>
                                                    {{
                                                        formatAmount(
                                                            item.total,
                                                            invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                            <div v-else>
                                                {{
                                                    formatAmount(
                                                        item.price * item.quantity,
                                                        invoice.currency || $store.state.Settings?.currency
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Subtotal -->
                            <div class="ph3-l ph2">
                                <div class="flex justify-between pb3 font-w1 ">
                                    <div class="w-30-l"></div>
                                    <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                        <div class="w-30 tl">Subtotal</div>
                                        <div class="w-30  tr">
                                            {{
                                                formatAmount(
                                                    invoice.subtotal,
                                                    invoice.currency || $store.state.Settings?.currency
                                                )
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Discount -->
                            <div v-if="invoice.hasDiscount" class="ph3-l ph2">
                                <div class="flex justify-between pb3 font-w1">
                                    <div class="w-30-l "></div>
                                    <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                        <div class="w-30 tl">Discount</div>
                                        <div v-if="invoice.discountType == 'fixed'" class="w-30 tr">
                                            {{
                                                formatAmount(
                                                    invoice.discount,
                                                    invoice.currency || $store.state.Settings?.currency
                                                )
                                            }}
                                        </div>
                                        <div v-else class="w-30 tr">{{ invoice.discount }}%</div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="invoice.hasCommission" class="ph3-l ph2">
                                <div class="flex justify-between pb3 font-w1">
                                    <div class="w-30-l "></div>
                                    <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                        <div class="w-30 tl">Commission</div>
                                        <div v-if="invoice.commissionType == 'fixed'" class="w-30 tr">
                                            {{
                                                formatAmount(
                                                    invoice.commission,
                                                    invoice.currency || $store.state.Settings?.currency
                                                )
                                            }}
                                        </div>
                                        <div v-else class="w-30 tr">{{ invoice.commission }}%</div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="invoice.hasShipping" class="ph3-l ph2">
                                <div class="flex justify-between pb3 font-w1">
                                    <div class="w-30-l "></div>
                                    <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                        <div class="w-30 tl">Shipping</div>
                                        <div v-if="invoice.shippingType == 'fixed'" class="w-30 tr">
                                            {{
                                                formatAmount(
                                                    invoice.shipping,
                                                    invoice.currency || $store.state.Settings?.currency
                                                )
                                            }}
                                        </div>
                                        <div v-else class="w-30 tr">{{ invoice.shipping }}%</div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="invoice.hasTax" class="ph3-l ph2">
                                <div class="flex justify-between pb3 font-w1">
                                    <div class="w-30-l "></div>
                                    <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                        <div class="w-30 tl">Tax</div>
                                        <div v-if="invoice.taxType == 'fixed'" class="w-30 tr">
                                            {{ formatAmount(invoice.tax, invoice.currency || $store.state.Settings?.currency) }}
                                        </div>
                                        <div v-else class="w-30 tr">{{ invoice.tax }}%</div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="invoice.hasOthersCharges" class="ph3-l ph2">
                                <div class="flex justify-between pb3 font-w1">
                                    <div class="w-30-l "></div>
                                    <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                        <div class="w-30 tl">{{ invoice.others }}</div>
                                        <div class="w-30 tr">
                                            {{
                                                formatAmount(
                                                    invoice.othersCharges,
                                                    invoice.currency || $store.state.Settings?.currency
                                                )
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="ph3-l ph2">
                                <div class="flex justify-between pb5 font-w1">
                                    <div class="w-30-l"></div>
                                    <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                        <div class="w-30 tl">Total</div>
                                        <div class="w-30 tr b " style="color: #132C8C;">
                                            {{
                                                formatAmount(
                                                    invoice.totalAmount,
                                                    invoice.currency || $store.state.Settings?.currency
                                                )
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="lh-copy pv3" style="border-top: 1px solid #e3e8ee; border-bottom: 1px solid #E3E8EE;">
                                All amounts are in {{ getCurrencyName(invoice.currency) }}. Please make the payment before
                                {{ formatDate(invoice.dateDue) }}.
                            </div>
                            <div
                                v-if="invoice.notes"
                                class="lh-copy pv3"
                                style="border-top: 1px solid #e3e8ee; border-bottom: 1px solid #E3E8EE;"
                            >
                                <div class="font-w1">Notes:</div>
                                {{ invoice.notes ? invoice.notes : '' }}
                            </div>
                        </div>
                    </div>

                    <div v-if="invoice?.paymentInfo" class="box-border mv4" style="background: #fbfcfe">
                        <div class="flex justify-between box-border-bottom pa3">
                            <div>Previous Payment(s)</div>
                        </div>
                        <div
                            v-for="(data, index) of invoice.paymentInfo"
                            v-bind:key="index"
                            class="pa3"
                            style="border-bottom: 1px solid #e3e8ee"
                        >
                            <div class="flex justify-between">
                                <div class="w-30">{{ data.account }}</div>
                                <div class="w-50 b">
                                    {{ formatAmount(data.amount, invoice?.currency || $store.state.Settings?.currency) }}
                                </div>
                                <div class="w-20 b">{{ data?.date ? formatDate(data.date) : '' }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="box-border mv4" style="background: #FBFCFE;">
                        <div class="flex justify-between box-border-bottom pa3">
                            <div>Selected payment option</div>
                        </div>
                        <div v-for="item of invoice.bankDetails" v-bind:key="item.bankAccountNumber" class="pa3">
                            <div class="flex flex-wrap justify-between items-center pa3 box-border">
                                <div>
                                    <div class="pb2 b">Bank Transfer</div>
                                    <div class="pb2">Pay by making a bank transfer to the recievers bank account</div>
                                    <div class="flex justify-between">
                                        <div class="w-30">Bank Name:</div>
                                        <div class="w-70 b">{{ item.bankName }}</div>
                                    </div>
                                    <div class="flex justify-between">
                                        <div class="w-30">Account Number:</div>
                                        <div class="w-70 b">{{ item.bankAccountNumber }}</div>
                                    </div>
                                    <div class="flex justify-between">
                                        <div class="w-30">Account Name:</div>
                                        <div class="w-70 b">{{ item.bankAccountName }}</div>
                                    </div>
                                </div>
                                <div class="pt0-l pt3">
                                    <img src="imgs/bank.svg" alt="" />
                                </div>
                            </div>
                        </div>

                        <div v-if="invoice?.paystackLink" class="pa3">
                            <div class="flex flex-wrap justify-between items-center pa3 box-border copy-text-wrapper">
                                <div>
                                    <div class="pb2 b">Paystack</div>
                                    <div>Pay through the secure paystack API</div>
                                    <div class="flex flex-wrap justify-between items-center pb2">
                                        <a :href="invoice.paystackLink" target="_blank" class="pr2">{{ invoice.paystackLink }}</a>
                                        <button @click="doCopy" class="pl0 pr0 pb0 pt0">
                                            <span class="icon">
                                                <font-awesome-icon icon="fa-solid fa-copy" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div class="pt0-l pt3">
                                    <img :src="require('@/assets/images/paystack.svg')" alt="paystack logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-wrap justify-between items-center center w-60">
                <div class="action-btns ph5-l">
                    <a href="#" @click.prevent="handleDownloadPDF(invoiceFormRef, invoice.number)" class="mr-2 ph4">
                        <img src="../../assets/images/direct-download.svg" alt="" /> Download
                    </a>
                    <!-- <a href="#" @click.prevent="printInvoice">
                        <img src="../../assets/images/printer.svg" alt=""/>
                    </a> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, computed, watch, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { copyText } from 'vue3-clipboard'
import { formatAmount, formatQuantity, getCurrencyName, getDaysFromToday, formatDate } from '@/utils/lib'
import { handleDownloadPDF } from '@/utils/lib'
import AppWrapper from '@/layout/AppWrapper'
import InvoiceHeader from '@/views/Invoice/widgets/InvoiceHeader'
import Pagination from '@/components/Pagination'

import Spinner from '@/components/Spinner'
import TableEmptyState from '@/components/TableEmptyState'
// import Filter from '@/components/Filter'

export default {
    name: 'InvoiceViewPublic',
    components: { AppWrapper, InvoiceHeader, Pagination, Spinner, TableEmptyState },

    setup() {
        const router = useRouter()
        const store = useStore()

        const isLoading = computed(() => store?.state.Invoice.isLoading)
        const invoice = computed(() => store?.state.Invoice.invoice)
        const orgInitials = computed(() => store?.state?.Invoice?.orgInitials)
        const route = useRoute()

        const initialState = {
            isLoading: true,
            invoiceId: route.params.id
        }

        const invoiceFormRef = ref(null)

        const state = reactive(initialState)

        const doCopy = () => {
            copyText(invoice.value.paystackLink, undefined, (error, event) => {
                if (!error) {
                    let copyText = document.querySelector('.copy-text-wrapper')
                    copyText.classList.add('active')
                    setTimeout(function() {
                        copyText.classList.remove('active')
                    }, 2500)
                }
            })
        }

        watch(invoice, (newValue, oldValue) => {
            if (newValue !== oldValue) {
                // console.log('invoice changed', newValue)
            }
        })

        onMounted(() => {
            store.dispatch('Invoice/getInvoicePublic', route.params.id)
        })

        return {
            isLoading,
            invoice,
            formatAmount,
            formatQuantity,
            getCurrencyName,
            getDaysFromToday,
            formatDate,
            state,
            invoiceFormRef,
            orgInitials,
            handleDownloadPDF,
            doCopy
        }
    },

    methods: {
        printInvoice() {
            this.$htmlToPaper('invoiceContainer')
        }
    }
}
</script>

<style scoped>
.directional-up {
    width: 15px;
    height: 15px;
}
.sort1 {
    padding: 6px;
    cursor: pointer;
}
.sort1:hover {
    background-color: rgb(237, 240, 253);
    color: #132c8c;
    border-radius: 5px;
}
@media (max-width: 765px) {
    .flex.bill-section {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
}
.org-img-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(215, 222, 250);
    height: 60px;
    width: 60px;
    border-radius: 50%;
    font-size: 25px;
    color: rgb(19, 44, 140);
    margin-right: 5px;
}
.invoice-status-badge {
    /* background: #FEEAD7;  */
    /* color: #FB8B24;  */
    border-radius: 20px;
}
.copy-text-wrapper button {
    border: none !important;
    outline: none !important;
    border-radius: 10px;
    cursor: pointer;
    background-color: unset !important;
    color: unset !important;
}

.copy-text-wrapper button:active {
    background: #809ce2;
}
.copy-text-wrapper button:before {
    content: 'Copied';
    position: absolute;
    top: -45px;
    right: 0px;
    background: #5c81dc;
    padding: 8px 10px;
    border-radius: 20px;
    font-size: 15px;
    display: none;
}
.copy-text-wrapper button:after {
    content: '';
    position: absolute;
    top: -20px;
    right: 25px;
    width: 10px;
    height: 10px;
    background: #5c81dc;
    transform: rotate(45deg);
    display: none;
}
.copy-text-wrapper.active button:before,
.copy-text-wrapper.active button:after {
    display: block;
}
</style>
